const Pricing = () => {
    return ( <div id="menu" className="pricing">
        <h2>Massage menu</h2>
        <div className="menu">
            <div className="menucategories deeptissue">
                <h3>Regular</h3>
                <ul>
                    <li>per 30mins  $60</li>
                    <hr />
                    <li>per 60mins  $100</li>
                    <hr />
                    <li>per 90mins  $150</li>
                    <hr />
                    <li>per 120mins  $180</li>
                    <hr />
                </ul>
            </div>
            <div className="menucategories special">
                <h3>Enhancements</h3>
                <ul>
                    <li>Deep tissue $30</li>
                    <hr />
                    <li>Swedish massage  $20</li>
                    <hr />
                    <li>Sports special $50</li>
                    <hr />
                    <li>Nuru massage $80</li>
                    <hr />
                </ul>
            </div>
        </div>
    </div> );
}
 
export default Pricing;