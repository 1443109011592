import { useState } from "react";
import emailjs from '@emailjs/browser';
import { Link } from "react-router-dom";
import React from "react";


const Personalinfo = () => {
    const [name, setname] = useState('')
    const [phone, setphone] = useState('')
    const [email, setemail] = useState('')
    const [requirements, setrequirements] = useState('')



    const mailme = async () => {

        if (name.length > 2) {
            console.log(name + phone + email)
            const emailcontent = {
                to_name: name,
                from_name: email,
                message: requirements,
                phone: phone
            }
            emailjs.send("service_esvwcci", "my_form", emailcontent, "fVIZEMUaJozlevZGM")
                .then((result) => {
                    console.log(result.text)
                }, (error) => {
                    console.log(error.text)
                })
        }
    }


    return (<div className="pinfo">
        <div className="input">
            <h4>Please fill in the details</h4>
            <form >
                <label htmlFor="">Name</label>
                <input type="text"
                    required
                    value={name}
                    placeholder="Name"
                    onChange={(e) => { setname(e.target.value) }}
                />
                <label htmlFor="">Phone(optional*)</label>
                <input type="number"
                    value={phone}
                    placeholder="Phone"
                    onChange={(e) => { setphone(e.target.value) }}
                />
                <label htmlFor="">Email</label>
                <input type="text"
                    required
                    value={email}
                    placeholder="email"
                    onChange={(e) => { setemail(e.target.value) }}
                />
                <label htmlFor="">Requirements</label>
                <textarea required
                    onChange={(e) => { setrequirements(e.target.value) }}
                    cols="30"
                    rows="10"
                    value={requirements}
                    placeholder="Give a highlight of how you want your session to be"></textarea>
                <button type="submit" onClick={() => { mailme() }}><Link to={'/payment'}>Proceed to payment</Link></button>
            </form>
        </div>
    </div>);
}

export default Personalinfo;