const Payment = () => {
    return ( <div className="payment">
        <h2>Please select your prefered payment option</h2>
        <p><b>Note : Please send a screenshot of your reciept for confirmation of successful payment to : <b>heavenlyspa@gmail.com</b>. Thank YOU!</b></p>
        <div className="paymentoption">
            <img src="/photos/cashapp.png" alt="" /><p>Cashapp</p>
              <p className="details">$kps9764</p>
        </div>
        <div className="paymentoption">
            <img src="/photos/paypal3.png" alt="" /> <p>paypal</p>
            <p className="details">kimberlysteele755@gmail.com</p>
        </div>
        <div className="paymentoption">
            <img src="/photos/btc.png" alt="" /> <p>bitcoin</p>
            <p className="details">1C9LFj5aDgWevjYTFt97fnsdjggSksoqHE</p>
        </div>
    </div> );
}
 
export default Payment;