import Footer from "./footer";
import Pricing from "./pricing";
import { Link } from "react-router-dom";

const Home = () => {
    return ( <div className="home">
        <div className="piccard">
            <div className="pic">
                <div className="text">the most trusted name in massage</div>
                <div className="text1">we bring you same-day, in-home massasge</div><div>wellness on the way</div> 
                <button><Link to={'/booking'}>Book a session</Link> </button>
            </div>
        </div>
        <Pricing/>
        <Footer/>
    </div> );
}
 
export default Home;