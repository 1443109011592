import './App.css';
import Home from './components/home';
import Navbar from './components/navbar';
import Payment from './components/payment';
import { BrowserRouter , Route, Switch } from 'react-router-dom';
import Personalinfo from './components/personalinfo';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar/>
        <Switch>
          <Route exact path='/'>
            <Home/>
          </Route>
          <Route exact path='/payment'>
            <Payment/>
          </Route>
          <Route exact path='/booking'>
            <Personalinfo/>
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
