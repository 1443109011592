const Footer = () => {
    return ( <div  className="footer">
        <div className="info">
            <div id="help" className="footerdetail contact">
                <h4>Contact</h4>
                <ul>
                    <li>
                        <a href="tel:+17015037006"><i className="fa fa-phone"   aria-hidden="true"></i> +17015037006</a>
                    </li>
                    <li>
                        <a href="mailto:heavenlyspa@gmail.com"><i className="fa  fa-envelope" aria-hidden="true"></i> Heavenlyspa@gmail.com</a>
                    </li>
                </ul>
            </div>
            <div id="about" className="footerdetail about">
                <h4>about us</h4>
                <div>Heavenly Sensation Spa is an in-home massage/spa therapy sessions where you can order soothing massages  that heals your mind and your body meeting you at your comfort </div>
            </div>
        </div>
        <div className="copyright">
            <p>&copy;copyright</p>
            <p>Powered by sterflick</p>
        </div>
    </div> );
}
 
export default Footer;